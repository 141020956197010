import FontFaceObserver from 'fontfaceobserver';
import { storageAvailable, storageGet, storageSet } from '../utils/localStorage';

function loadFontAndActivate() {
  function activateFont() {
    document.documentElement.classList.add('wf-active');
  }
  if (storageAvailable && storageGet('fonts')) {
    activateFont();
    return;
  }

  const font = new FontFaceObserver('Open Sans');
  const maxRetries = 3;
  let retryCount = 0;

  Promise.all([font.load()]).then(
    () => {
      setTimeout(() => {
        activateFont();
        if (storageAvailable) {
          try {
            storageSet('fonts', 'true');
          } catch (e) {
            throw new Error(`Failed to set sessionStorage: ${e.message}`);
          }
        }
      }, 0);
    },
    (err) => {
      if (retryCount >= maxRetries) {
        throw new Error(`Font loading timeout: ${err}`);
      }
      retryCount += 1;
      loadFontAndActivate();
    },
  );
}

export default loadFontAndActivate;
